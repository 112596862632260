@import '../../../../../style/variables';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  .login-form {
    @include backgroundShadow3;
    display: grid;
    grid-row-gap: 1rem;
    width: 25rem;
    grid-template-rows: repeat(4, auto) 0fr;
    padding: 1rem;
    border-radius: 1rem;

    .form-title {
      font-size: 1.2rem;
      color: $color-blue-1;
    }
  }
}