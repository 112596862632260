@import '../../../../../style/variables';

.tracker-data {
  .body-container {
    display: grid;
    grid-row-gap: 1rem;

    .row {
      display: flex;
      column-gap: .5rem;
      align-items: center;
    }

    .garbage-types {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: .5rem 1rem;
    }
  }
}