@import '../../../../style/variables';

.tracker-list {
  @include backgroundShadow3;
  padding: 1rem;
  border-radius: 1rem;
  width: 90dvw;

  .row {
    grid-template-columns: 6rem 8rem 1fr 8rem 8rem 1rem;
  }
}